import { EN_DASH, THEME } from "@digitallab/grid-common-components";
import { Notify } from "@digitallab/grid-common-components";

import { useHistory, useLocation } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import { v4 } from "uuid";
import { changeTimeFormat } from "../features/log-book/helpers";
import { StepperForm } from "./StepperForm";
import { useState } from "react";
import ItemWrapper from "../features/log-book/log-book-item-form-dialog/item-context/ItemWrapper";
import { actionLogValidationSchema } from "./actionLogValidationSchema";
import { runLogValidationSchema } from "./runLogValidationSchema";
import { useSearchParameters } from "../components/shared/useSearchParameters";
import { RUN_STATUS_FIELD } from "../constants";
import { DigitalLabLogbookInstrumentModelType } from "../models/DigitalLabLogbookInstrumentModelType";
import { withApollo, WithApolloClient } from "react-apollo";
import { useSelector } from "react-redux";

import {
  createActionLogBulkMutation,
  createActionLogBulkVariables,
  createRunLogBulkMutation,
  createRunLogBulkVariables
} from "./mutationHelpers";
import { VALIDATE_ELN_ID } from "../gql/logBooksapi/queries";

export interface ISelectValues {
  key: string;
  value: string;
}
interface IEndorAttachment {
  createdAt: string;
  addedBy: string;
  status: string;
  filename: string;
  fileDescription: string;
  checksum: null;
  targetModule: string;
  fielId: string;
  groupId: string;
  bucketRegion: string;
  groupMetadataVersion: string;
}
export interface IActionLogFormValues {
  actionDate: Date;
  actionTime: string;
  action: ISelectValues;
  description: string;
  ecrNumber?: string;
  gxpReady: ISelectValues;
  operatorUserName?: string;
  systemStatus: ISelectValues;
  externalDocument?: { link: string; name: string }[];
  attachments?: IEndorAttachment[];
  updatedSoftwareVersion?: { newValue: string; shouldBePublished: boolean };
}

export interface IRunLogFormValues {
  runStartDate: Date;
  runEndTime?: string;
  runEndDate: Date;
  runStartTime?: string;
  runStatus: keyof typeof RUN_STATUS_FIELD;
  runIdentification?: string;
  samplesProcessed?: string;
  numberOfRuns?: string;
  description: string;
  defectId?: string;
  eLNid?: string;
  mediaType?: ISelectValues[];
  tipsUsed?: ISelectValues;
  operatorUserName?: string;
  assay: string[];
}

const actionLogInitialValues = {
  logSheetEntryId: v4(),
  actionDate: new Date(),
  actionTime: changeTimeFormat(new Date()),
  updatedSoftwareVersion: {
    newValue: null,
    shouldBePublished: null
  }
};

const runLogInitialValues = {
  runStartDate: new Date(),
  runEndDate: new Date(),
  assay: [], // RunSelectWithOwnProps and CustomAutocompleteWithOwnProp that are used for assay input - needs to get an array to work correctly, for undefined it will crush
  mediaType: [] //Here - if log created with null value, then not possible to open it later for edition.
};

interface IEquipmentList {
  equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[];
}

interface ILogForm extends IEquipmentList {
  actionLogForm?: IActionLogFormValues;
  runLogForm?: IRunLogFormValues;
}

type ILogFormValues = IEquipmentList & (IActionLogFormValues | IRunLogFormValues);

interface ILogSavedResponse {
  saved: number;
  failed: { model: string; nickName: string }[];
}

export const StepperContainer = withApollo(({ client }: { client: WithApolloClient<any> }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { pathname } = useLocation();
  const { equipmentList, runLogForm, actionLogForm } = useSearchParameters() as unknown as ILogForm;
  const isRunLog = pathname.includes("/logs/run");
  const history = useHistory();
  const systemStatuses = useSelector(
    (store) => (store as { runLogsForm: { systemStatuss: ISelectValues[] } }).runLogsForm.systemStatuss
  );
  const user = useSelector((state) => (state as { user: any }).user);
  const eLNidValidationCheck = async (eLNid: string) => {
    try {
      const result = await client.query({
        query: VALIDATE_ELN_ID,
        fetchPolicy: "no-cache",
        variables: {
          eLNid
        }
      });
      return result?.data?.getElnIdValidation;
    } catch (err) {
      console.warn(err);
      Notify({
        type: "warning",
        icon: "alarm",
        title: "",
        text: "Unable to verify eLN ID (either eLN or the interface is down)",
        closeButton: true
      });
    } finally {
    }
  };

  const handleSubmit = async (isRunLog: boolean, values: ILogFormValues, actions: FormikHelpers<ILogFormValues>) => {
    if (isRunLog && "eLNid" in values && values.eLNid) {
      const eLNidValidation = await eLNidValidationCheck(values.eLNid);
      switch (eLNidValidation?.statusCode) {
        case 400:
          Notify({
            type: "warning",
            icon: "alarm",
            title: "",
            text: "Unable to verify eLN ID (either eLN or the interface is down)",
            closeButton: true
          });
          break;
        case 403:
          Notify({
            type: "warning",
            icon: "alarm",
            title: "",
            text: "Invalid eLN experiment ID",
            closeButton: true
          });
          break;
        case 200:
          break;
        default:
          Notify({
            type: "warning",
            icon: "alarm",
            title: "",
            text: `Unexpected response - status: ${eLNidValidation?.statusCode}`,
            closeButton: true
          });
      }
      if (eLNidValidation?.statusCode !== 200) {
        actions.setSubmitting(false);
        return;
      }
    }
    history.push("/");

    const { equipmentList, ...formValues } = values;
    const logType = isRunLog ? "Run" : "Action";
    try {
      const missingIds = equipmentList.filter((item) => !item.inventoryId);
      if (missingIds.length > 0) {
        throw new Error("non-existent Inventory ID");
      }
      const response = await client.mutate({
        mutation: isRunLog ? createRunLogBulkMutation(equipmentList) : createActionLogBulkMutation(equipmentList),
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        variables: isRunLog
          ? createRunLogBulkVariables(formValues as IRunLogFormValues, equipmentList, user, systemStatuses)
          : createActionLogBulkVariables(formValues as IActionLogFormValues, equipmentList, user, systemStatuses)
      });
      if (response.errors?.length === equipmentList.length || response.data === null) throw new Error("all failed");
      if (response.data) {
        const data = Object.values(response.data as ILogSavedResponse);
        if (data.some((value) => value === null)) {
          const failedList = data
            .map((response, index) =>
              response
                ? undefined
                : `${equipmentList[index]?.equipmentModel} ${EN_DASH} ${equipmentList[index]?.equipmentNickName}`
            )
            .filter(Boolean);

          Notify({
            title: "",
            icon: "circle_confirm",
            type: "success",
            text: `Successfully added ${equipmentList.length - failedList.length} ${logType} log(s)!`
          });
          Notify({
            title: "",
            type: "warning",
            icon: "alarm",
            text: `Failed to add ${logType} log(s) for ${failedList.join(", ")}`,
            toastOptions: {
              autoClose: false
            }
          });
        } else
          Notify({
            title: "",
            icon: "circle_confirm",
            type: "success",
            text: `Successfully added ${logType} log(s)!`
          });
      }
    } catch (error) {
      if ((error as Error).message === "non-existent Inventory ID")
        Notify({
          title: "",
          type: "warning",
          icon: "alarm",
          text: `Log edit/creation failed due to non-existent Inventory ID`,
          toastOptions: {
            autoClose: false
          }
        });
      else
        Notify({
          title: "",
          type: "warning",
          icon: "alarm",
          text: `Failed to add ${logType} log(s) for all selected equipment(s)!`
        });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: THEME["one-color-cobas-blue-50"],
        padding: THEME["one-spacer-16"],
        boxSizing: "border-box"
      }}
    >
      <Formik
        initialValues={
          isRunLog
            ? {
                ...(runLogForm || runLogInitialValues),
                equipmentList
              }
            : {
                ...(actionLogForm || actionLogInitialValues),
                equipmentList
              }
        }
        validationSchema={isRunLog ? runLogValidationSchema : actionLogValidationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(
            isRunLog,
            values as unknown as (IActionLogFormValues | IRunLogFormValues) & {
              equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[];
            },
            actions as FormikHelpers<ILogFormValues>
          );
        }}
      >
        <ItemWrapper setOpenDialog={setOpenDialog} openDialog={openDialog}>
          <StepperForm equipmentList={equipmentList} />
        </ItemWrapper>
      </Formik>
    </div>
  );
});
